import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'components/Link';
import { TagType } from 'config/links';
import { H3 } from 'typography';
import { Col } from 'components/layouts';
import { IconArrowRight } from 'components/Icon';
import Anchor from 'components/Anchor';
import { Variant } from './LinkCard.constants';

const LinkCardStyled = styled(Col).attrs((props) => ({
    span: 12,
    mediumTablet: 6,
    mediumDesktop: props.numberOfCards ? 12 / props.numberOfCards : undefined,
}))`
    min-width: 272px;
    max-width: 400px;
    background-color: ${({ theme, variant }) =>
        variant === Variant.GREY ? theme.palette.grey[200] : theme.palette.white};
    border-radius: ${({ theme }) => theme.borderRadius.regular};
    padding: ${({ theme }) => theme.spacing[4]};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        padding: ${({ theme }) => theme.spacing[6]};
    }
`;

const InformationHeadingStyled = styled(H3)`
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const ParagraphStyled = styled.p`
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

const LinkStyled = styled(({ tag, url, ...props }) =>
    // eslint-disable-next-line react/jsx-props-no-spreading
    tag === TagType.A ? <Anchor href={url} {...props} /> : <Link to={url} {...props} />
)(
    ({ theme: { typography, fontWeights, palette } }) => css`
        ${typography.p};
        font-weight: ${fontWeights.bold};
        color: ${palette.link};
        text-decoration: underline;
        padding: 0;
        margin-top: auto;
        display: flex;
        align-items: center;
    `
);

const IconStyled = styled(IconArrowRight)`
    margin-left: ${({ theme }) => theme.spacing[1]};
    & svg {
        fill: ${({ theme }) => theme.palette.link};
    }
`;

export { LinkCardStyled, InformationHeadingStyled, ParagraphStyled, LinkStyled, IconStyled };
