import React from 'react';

import { LinkCardGridStyled } from './LinkCardGrid.style';

const LinkCardGrid = ({ children }) => (
    <LinkCardGridStyled>
        {React.Children.map(children, (child) =>
            React.cloneElement(child, {
                numberOfCards: children.length,
            })
        )}
    </LinkCardGridStyled>
);

export default LinkCardGrid;
