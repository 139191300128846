import React from 'react';
import {
    LinkCardStyled,
    InformationHeadingStyled,
    ParagraphStyled,
    LinkStyled,
    IconStyled,
} from './LinkCard.style';
import { Variant } from './LinkCard.constants';

const LinkCard = ({
    informationHeading,
    textBody,
    linkURL,
    linkTag,
    linkText,
    numberOfCards,
    variant,
    className,
    onClick,
}) => {
    const canRenderLink = Boolean(linkText) && Boolean(linkURL);

    return (
        <LinkCardStyled numberOfCards={numberOfCards} className={className} variant={variant}>
            <InformationHeadingStyled>{informationHeading}</InformationHeadingStyled>
            <ParagraphStyled>{textBody}</ParagraphStyled>
            {canRenderLink ? (
                <LinkStyled url={linkURL} tag={linkTag} onClick={onClick}>
                    {linkText}
                    <IconStyled size="small" />
                </LinkStyled>
            ) : null}
        </LinkCardStyled>
    );
};

LinkCard.defaultProps = {
    variant: Variant.GREY,
};

LinkCard.Variant = Variant;

export default LinkCard;
