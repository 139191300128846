/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';

import { FlexboxGrid } from 'components/layouts';

const LinkCardGridStyled = styled(FlexboxGrid).attrs({
    justify: FlexboxGrid.Justify.CENTER,
})`
    align-items: stretch;
`;

export { LinkCardGridStyled };
